<script>
  import servicePic1 from './images/pic1.png'
  import servicePic2 from './images/pic2.png'
  import servicePic3 from './images/pic3.png'
  import servicePic4 from './images/pic4.png'
  import * as url from 'url'
  import FloatBar from '@/components/FloatBar/FloatBar.vue'
  import FloatAds from '@/components/FloatAds/FloatAds.vue'


  export default {
    name: 'OverseasTutoring',
    components: { FloatBar, FloatAds },
    computed: {
      url () {
        return url
      }
    },
    data () {
      return {
        serviceData: [
          {
            img: servicePic1,
            title: '师资',
            desc: 'G5牛剑名校硕博导师，一对一辅导'
          },
          {
            img: servicePic2,
            title: '服务',
            desc: '签订正规辅导合同，贴心服务有保障'
          },
          {
            img: servicePic3,
            title: '教学',
            desc: '定制专属化课程，有针对性地解决学生问题'
          },
          {
            img: servicePic4,
            title: '便捷',
            desc: '课程实时录播无限次回放，知识点不遗漏'
          }
        ],
        courseData: [
          {
            title: '人文社科',
            content: `英语、文学、历史、教育
学、社会学、传媒、法
学、心理学、国际关系、
哲学等`
          },
          {
            title: '商科',
            content: `金融学、经济学、会计
学、会计与金融、管理
学、金融工程、金融数
学、市场营销、人力资源
管理等`

          },
          {
            title: '理工科',
            content: `计算机科学、统计学、数
学、物理、化学、生物、
建筑学、通信工程、电子
工程、电气工程、土木工
程等`
          }
        ],
        activeCourse: ''
      }
    },
    methods: {
      addAnimation ( item ) {
        this.activeCourse = item.title
      },
      removeAnimation () {
        this.activeCourse = ''
      }
    }
  }
</script>

<template>
  <div class="overseas-tutoring">
    <div class="section section-banner">
      <div class="banner-wrapper">
        <div class="left-text">
          <div class="line1">留学生课程辅导 你的GPA提分管家</div>
          <div class="line2">G5导师1V1辅导</div>
          <div class="line3">24小时无时差服务 贴心及时</div>
          <div class="line4">覆盖英国、澳洲、美国、加拿大、新加坡、中国香港等英语系授课地区
            <br />
            覆盖海外预科、本科、硕士、博士等阶段
          </div>
        </div>
        <div class="right-code">
          <img src="./images/qrcode.png" alt="">
          <span>扫码制定专属学习方案</span>
        </div>
      </div>
    </div>
    <div class="section section-qa">
      <div class="qa-top">
        <div class="qa-title">你是否遇到了这些问题？</div>
        <div class="question-box">
          <div class="question-item question-item1">不清楚目标专业课程设置</div>
          <div class="question-item question-item2">导师布置的作业一头雾水</div>
          <div class="question-item question-item3">不了解中外教育差异</div>
          <div class="question-item question-item4">课上听不懂教授在说什么</div>
          <div class="question-item question-item5">考试成绩差，论文不会写</div>
          <div class="question-item question-item6">不熟悉海外授课模式</div>
          <div class="question-item question-item7">想提升GPA，却不知道怎么去做</div>
        </div>
      </div>
      <div class="qa-divided"></div>
      <div class="qa-bottom">
        <div class="answer-title">我们能帮助你什么？</div>
        <div class="answer-box">
          <div class="answer-item answer-item1">
            原版课件教学，中英双语授课，同步学校进程，保障同学实时跟进教学进度，做到每节课都不留疑问
          </div>
          <div class="answer-item answer-item2">
            课后作业辅导，导师全程陪同学生完成作业，实时为同学讲解作业中遇到的难题，保证同学完成作业的质量和效率
          </div>
          <div class="answer-item answer-item7">考前冲刺辅导，导师讲解重难点知识，分析考试题型，并传授答题技巧</div>
        </div>
      </div>
    </div>
    <div class="section section-service">
      <div class="service-center">
        <div>留学生</div>
        <div>课业辅导</div>
      </div>
      <div class="service-box">
        <div class="service-item" v-for="item in serviceData" :key="item.title">
          <img :src="item.img" alt="">
          <div class="item-text">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-flow">
      <div class="title">海外课业辅导服务流程</div>
      <img src="./images/part3-cont.png" alt="">
    </div>
    <div class="section section-team">
      <div class="title">G5导师授课团队</div>
      <img src="./images/part4-cont.png" alt="">
    </div>
    <div class="section section-course">

      <div class="course-wrapper">
        <div class="course-title">热门专业全覆盖</div>
        <div class="course-box">
          <div class="course-item" v-for="item in courseData" :class="activeCourse === item.title ? 'slideInUp' : ''"
               :key="item.title"
               @mouseover.stop.prevent="addAnimation(item)"
               @mouseleave=removeAnimation(item)
          >
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>

      </div>
    </div>
    <div class="section section-subject">
      <div class="title">部分学科清单</div>

      <img src="./images/part6-pic.png" alt="">
    </div>
    <float-bar category="ielts"></float-bar>
    <float-ads></float-ads>
  </div>
</template>

<style scoped lang="less">
  .overseas-tutoring {
    width: 100%;

    .section {
      width: 100%;
    }

    .section-banner {
      box-sizing: border-box;

      background: url("./images/banner-bg1.png") no-repeat center;
      background-size: cover;
      color: #fff;
      font-weight: 700;

      .banner-wrapper {
        max-width: 876px;
        margin: 0 auto;
        padding: 86px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .left-text {
        text-align: left;
      }

      .right-code {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 12px;

        img {
          width: 120px;
          height: 120px;
          margin-bottom: 10px;
        }
      }

      .line1 {
        font-size: 20px;

      }

      .line2 {
        font-size: 50px;
        margin: 10px 0;
      }

      .line3 {
        font-size: 26px;
        margin-bottom: 40px;
      }

      .line4 {
        font-size: 16px;
        font-weight: 500;
        font-family: "PingFang SC";
      }
    }

    .section-qa {
      background: url("./images/part1-bg.png") no-repeat center;
      background-size: cover;
      display: flex;
      align-items: center;
      flex-direction: column;

      .qa-top {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .qa-title {
        width: 304px;
        padding: 8px 20px;
        font-weight: 700;
        font-size: 24px;
        background: #3764EC;
        border-radius: 0 0 22px 22px;
        color: #fff;
      }

      .question-box {
        position: relative;
        width: 756px;
        height: 144px;
        margin: 20px auto;

        .question-item {
          position: absolute;
          padding: 4px 12px;
          font-size: 14px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 30px;
          text-align: center;
          font-size: 18px;
          color: #fff;
          cursor: pointer;
        }

        .question-item1 {
          top: 12px;
          left: 121px;
          font-size: 14px;
        }

        .question-item2 {
          top: 0;
          right: 166px;
          font-size: 16px;
        }

        .question-item3 {
          top: 58px;
          left: 0;
          font-size: 16px;
        }

        .question-item4 {
          top: 50px;
          left: 248px;
          font-size: 18px;
        }

        .question-item5 {
          top: 49px;
          right: 0;
          font-size: 18px;
        }

        .question-item6 {
          bottom: 0;
          left: 98px;
          font-size: 16px;
        }

        .question-item7 {
          bottom: 2px;
          right: 150px;
          font-size: 14px;
        }

        .question-item:hover {
          font-weight: bold;
          color: #2952E2;
          background: rgba(255, 255, 255, 0.4);
          box-shadow: 0 2px 16px 0 #3d3d3d1a;
        }

        .question-item1:hover {
          top: 10px;
          font-size: 16px;
        }

        .question-item2:hover {
          top: 0px;
          font-size: 18px;
        }

        .question-item3:hover {
          top: 56px;
          font-size: 18px;
        }

        .question-item4:hover {
          top: 48px;
          font-size: 20px;
          transform: scale(1.1);
        }

        .question-item5:hover {
          top: 47px;
          font-size: 20px;
        }

        .question-item6:hover {
          bottom: 0px;
          font-size: 18px;
        }

        .question-item7:hover {
          bottom: 2px;
          font-size: 16px;
        }
      }

      .qa-divided {
        width: 1200px;
        margin: 20px auto;
        background: #fff;
        height: 0.5px;
      }

      .qa-bottom {
        width: 1200px;
        margin: 20px auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .answer-title {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 20px;
          color: #fff;
        }

        .answer-box {
          display: flex;
          width: 1200px;
          color: rgba(0, 0, 0, 0.7);
          justify-content: space-between;

          .answer-item {
            box-sizing: border-box;
            padding: 0 30px;
            width: 320px;
            height: 140px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(255, 255, 255, 0.7);
            border: 1px solid #fff;
            border-radius: 16px;
          }
        }
      }
    }

    .section-service {
      position: relative;

      .service-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 180px;
        height: 180px;
        border: 6px solid rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        background: #fff;
        text-align: center;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.9);
        display: flex;
        justify-content: center;
        flex-direction: column;
        z-index: 2;
      }

      .service-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .service-item {
          width: 50%;
          position: relative;
          font-size: 0;

          img {
            width: 100%;
          }

          .item-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: #fff;

            .title {
              font-size: 42px;
            }

            .desc {
              font-size: 20px;
            }
          }
        }
      }
    }

    .section-flow {
      background: #E9F5FA;
      padding: 40px;

      .title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.9);
      }

      img {
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    .section-team, .section-subject {
      padding: 40px;
      background: #fff;

      .title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
        color: rgba(0, 0, 0, 0.9);
      }

      img {
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    .section-course {
      background: url("./images/part5-bg.png") no-repeat center;
      background-size: cover;
      padding: 40px;

      .course-title {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 50px;
        color: #fff;
      }

      .course-box {
        width: 980px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .course-item {
          width: 290px;
          height: 290px;
          border-radius: 50%;
          border: 8px solid rgba(0, 0, 0, 0.3);
          background: radial-gradient(50% 50% at 50% 50%, #10BD6E 24%, #29D087 100%);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;
          transition: all .2s linear;
          top: 0;


          .title {
            background: #FBFF2E;
            border-radius: 40px;
            padding: 9px 20px;
            color: #019C58;
            font-size: 26px;
            margin-bottom: 12px;
          }

          .content {
            color: #fff;
            font-size: 20px;
            white-space: break-spaces;
          }
        }

        .slideInUp {
          top: -40px;
        }
      }
    }
  }
</style>
