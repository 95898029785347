var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overseas-tutoring"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"section section-service"},[_vm._m(2),_c('div',{staticClass:"service-box"},_vm._l((_vm.serviceData),function(item){return _c('div',{key:item.title,staticClass:"service-item"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"item-text"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(item.desc))])])])}),0)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"section section-course"},[_c('div',{staticClass:"course-wrapper"},[_c('div',{staticClass:"course-title"},[_vm._v("热门专业全覆盖")]),_c('div',{staticClass:"course-box"},_vm._l((_vm.courseData),function(item){return _c('div',{key:item.title,staticClass:"course-item",class:_vm.activeCourse === item.title ? 'slideInUp' : '',on:{"mouseover":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addAnimation(item)},"mouseleave":function($event){return _vm.removeAnimation(item)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"content"},[_vm._v(_vm._s(item.content))])])}),0)])]),_vm._m(5),_c('float-bar',{attrs:{"category":"ielts"}}),_c('float-ads')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-banner"},[_c('div',{staticClass:"banner-wrapper"},[_c('div',{staticClass:"left-text"},[_c('div',{staticClass:"line1"},[_vm._v("留学生课程辅导 你的GPA提分管家")]),_c('div',{staticClass:"line2"},[_vm._v("G5导师1V1辅导")]),_c('div',{staticClass:"line3"},[_vm._v("24小时无时差服务 贴心及时")]),_c('div',{staticClass:"line4"},[_vm._v("覆盖英国、澳洲、美国、加拿大、新加坡、中国香港等英语系授课地区 "),_c('br'),_vm._v(" 覆盖海外预科、本科、硕士、博士等阶段 ")])]),_c('div',{staticClass:"right-code"},[_c('img',{attrs:{"src":require("./images/qrcode.png"),"alt":""}}),_c('span',[_vm._v("扫码制定专属学习方案")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-qa"},[_c('div',{staticClass:"qa-top"},[_c('div',{staticClass:"qa-title"},[_vm._v("你是否遇到了这些问题？")]),_c('div',{staticClass:"question-box"},[_c('div',{staticClass:"question-item question-item1"},[_vm._v("不清楚目标专业课程设置")]),_c('div',{staticClass:"question-item question-item2"},[_vm._v("导师布置的作业一头雾水")]),_c('div',{staticClass:"question-item question-item3"},[_vm._v("不了解中外教育差异")]),_c('div',{staticClass:"question-item question-item4"},[_vm._v("课上听不懂教授在说什么")]),_c('div',{staticClass:"question-item question-item5"},[_vm._v("考试成绩差，论文不会写")]),_c('div',{staticClass:"question-item question-item6"},[_vm._v("不熟悉海外授课模式")]),_c('div',{staticClass:"question-item question-item7"},[_vm._v("想提升GPA，却不知道怎么去做")])])]),_c('div',{staticClass:"qa-divided"}),_c('div',{staticClass:"qa-bottom"},[_c('div',{staticClass:"answer-title"},[_vm._v("我们能帮助你什么？")]),_c('div',{staticClass:"answer-box"},[_c('div',{staticClass:"answer-item answer-item1"},[_vm._v(" 原版课件教学，中英双语授课，同步学校进程，保障同学实时跟进教学进度，做到每节课都不留疑问 ")]),_c('div',{staticClass:"answer-item answer-item2"},[_vm._v(" 课后作业辅导，导师全程陪同学生完成作业，实时为同学讲解作业中遇到的难题，保证同学完成作业的质量和效率 ")]),_c('div',{staticClass:"answer-item answer-item7"},[_vm._v("考前冲刺辅导，导师讲解重难点知识，分析考试题型，并传授答题技巧")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"service-center"},[_c('div',[_vm._v("留学生")]),_c('div',[_vm._v("课业辅导")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-flow"},[_c('div',{staticClass:"title"},[_vm._v("海外课业辅导服务流程")]),_c('img',{attrs:{"src":require("./images/part3-cont.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-team"},[_c('div',{staticClass:"title"},[_vm._v("G5导师授课团队")]),_c('img',{attrs:{"src":require("./images/part4-cont.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-subject"},[_c('div',{staticClass:"title"},[_vm._v("部分学科清单")]),_c('img',{attrs:{"src":require("./images/part6-pic.png"),"alt":""}})])
}]

export { render, staticRenderFns }